import * as Models from "static/js/app/models/__index";
import { SeoUrls } from "static/js/app/modules/seoUrls";

export class Page {
	public static queryString: { [index: string]: string } = {};
	public static getSearchUrl(search: Models.VehicleSearch, resetSortAndPage?:boolean): string {		
		return Page.getSeoSearchUrl(search, resetSortAndPage || false);
		
	}

	public static ready(fn: () => void) {
		// https://gomakethings.com/a-native-javascript-equivalent-of-jquerys-ready-method/

		// Sanity check
		if ( typeof fn !== 'function' ) {
			return;
		}

		// If document is already loaded, run method
		if ( document.readyState !== 'loading'  ) {
			return fn();
		}

		// Otherwise, wait until document is loaded
		window.addEventListener( 'DOMContentLoaded', fn, false );
	}
	
	public static initialize() {
		var qsParts = window.location.search
		.substr(1,window.location.search.length-1)
		.split('&');

		var qsParams: { [index: string]: string } = {};
		for(var i=0; i<qsParts.length; i++)
		{
			var kvp = qsParts[i].split('=');
			qsParams[kvp[0]] = (kvp[1] !== null && kvp[1] !== undefined) ? decodeURIComponent(kvp[1].replace(/\+/g, ' ')) : '';
		}

		Page.queryString = qsParams;
	}

	public static pushUrlVariablesToQueryString() {
		// add or replace query string params with seo url parms as applicable
		var seoUrlParams = SeoUrls.getSeoUrlParms(window.location.pathname);

		Object.keys(seoUrlParams).forEach(function(key) {
			Page.queryString[key] = (seoUrlParams[key] !== undefined && seoUrlParams[key] !== null && seoUrlParams[key].length > 0)
				? seoUrlParams[key]
				: Page.queryString[key];
		});
	}

	private static argOrBlank(val: string, argName: string, suffix?:string): string {
		return (val !== undefined && val !== null && val.length > 0) ? argName + '=' + val + (suffix || '') : '';
	}

	public static getSeoSearchUrl(search: Models.VehicleSearch, resetSortAndPage: boolean) {		
		var rootUrlPath = (search.rootUrl != null && search.rootUrl.length > 0)
			? search.rootUrl.substr(0, search.rootUrl.length-1)
			: "/used";
		
		var vehicleTypePart = (search.vehicleType)  ? '/' + search.vehicleType 	: '';
		var fuelTypePart 	= (search.fuelType)  	? '/' + search.fuelType 	: '';
		var bodyTypePart 	= (search.bodyType) 	? '/' + search.bodyType 	: '';
		var gearboxTypePart = (search.gearboxType)  ? '/' + search.gearboxType 	: '';
		var colourPart 		= (search.colour)  		? '/' + search.colour 		: '';
		var makePart		= (search.make) 		? '/' + search.make 		: '';
		var modelPart 		= (search.model) 		? '/' + search.model 		: '';
		
		
		var url = `${rootUrlPath}${vehicleTypePart}${
		(makePart + modelPart != '' ? makePart+ modelPart :
		bodyTypePart != '' && document.URL.indexOf(bodyTypePart) > -1 ? bodyTypePart :		
		fuelTypePart != '' && document.URL.indexOf(fuelTypePart) > -1 ? fuelTypePart : 
		gearboxTypePart != '' && document.URL.indexOf(gearboxTypePart) == -1 ? gearboxTypePart :
		colourPart != '' && document.URL.indexOf(colourPart) == -1 ? colourPart : '')}/`;						
		const urlBefore = url;
		if(!resetSortAndPage) {
			if(url[url.length-1] != '?') { 
				url += '?';
			}
			
			url += Page.argOrBlank(search.berth, 'berth', '&') +
				Page.argOrBlank(search.doors, 'doors', '&') +
				(url.indexOf(fuelTypePart) == -1 ? Page.argOrBlank(search.fuelType, 'fueltype', '&') : '') +
				(url.indexOf(bodyTypePart) == -1 ? Page.argOrBlank(search.bodyType, 'body', '&') : '') +
				(url.indexOf(gearboxTypePart) == -1 ? Page.argOrBlank(search.gearbox, 'gearbox', '&') : '') +
				(url.indexOf(gearboxTypePart) == -1 ? Page.argOrBlank(search.gearboxType, 'gearboxtype', '&') : '') +				
				(url.indexOf(colourPart) == -1 ? Page.argOrBlank(search.colour, 'colour', '&') : '') +				
				Page.argOrBlank(search.minEngineSize, 'min-engine-size', '&') +
				Page.argOrBlank(search.maxEngineSize, 'max-engine-size', '&') +
				Page.argOrBlank(search.insuranceGroup, 'insurance-group', '&') +
				Page.argOrBlank(search.keywords, 'keywords', '&') +
				Page.argOrBlank(search.minMpg, 'min-mpg', '&') +
				Page.argOrBlank(search.maxMpg, 'max-mpg', '&') +
				Page.argOrBlank(search.minLength, 'min-length', '&') +
				Page.argOrBlank(search.maxLength, 'max-length', '&') +
				Page.argOrBlank(search.minUnladenWeight, 'min-unladen-weight', '&') +
				Page.argOrBlank(search.maxUnladenWeight, 'max-unladen-weight', '&') +
				Page.argOrBlank(search.year, 'year', '&') +
				Page.argOrBlank(search.vehicles, 'vehicles', '&') +
				Page.argOrBlank(search.branch, 'branch', '&') +
				Page.argOrBlank(search.seats, 'seats', '&') +
				Page.argOrBlank(search.ulez, 'ulez', '&') +
				Page.argOrBlank(search.minPrice, 'minprice', '&') +
				Page.argOrBlank(search.maxPrice, 'maxprice', '&') +
				Page.argOrBlank(search.minMonthlyPrice, 'minmonthlypayment', '&') +
				Page.argOrBlank(search.maxMonthlyPrice, 'maxmonthlypayment', '&') +
				Page.argOrBlank(search.sortBy, 'sort-by', '&');

			if( (search.sortBy !== undefined && search.sortBy !== null && search.sortBy.length > 0) &&
				(search.sortOrder !== undefined && search.sortOrder !== null && search.sortOrder.length > 0)) {
				url += Page.argOrBlank(search.sortOrder, 'order', '&');
			}	
			console.log(`${urlBefore} - ${url}`);
		}

		return url.replace(/[&\?]+$/, '');
	}
}
Page.initialize();